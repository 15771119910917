import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormGuard } from './guards/form.guard';

const routes: Routes = [
  {
    path: 'healthcheck',
    loadChildren: () => import('./pages/healthcheck/healthcheck.module').then(m => m.HealthCheckModule)
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'serial',
    loadChildren: () => import('./pages/serial/serial.module').then(m => m.SerialModule),
    canActivate: [FormGuard],
  },
  {
    path: 'channel',
    loadChildren: () => import('./pages/channel/channel.module').then(m => m.ChannelModule),
    canActivate: [FormGuard],
  },
  {
    path: 'success',
    loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessModule),
    canActivate: [FormGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
