import { inject} from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ActivationFormService } from '../services/activation-form.service';

export const FormGuard: CanActivateFn = (route, state) => {
  const activationFormService: ActivationFormService = inject(ActivationFormService);
  const router = inject(Router);

  if (!activationFormService.getActivationCodeConfirmed()) {
    router.navigate(['']);
    return false;
  }

  switch (state?.url) {
    case '/serial':
      return true;
    case '/channel':
      if(activationFormService.getStep2Valid()) return true;

      router.navigate(['/serial']);
      return false;
    case '/success':
      if(activationFormService.getStep2Valid() ) {
        if(activationFormService.getChannelsValid())return true;

        router.navigate(['/channel']);
        return false;
      } else {
        router.navigate(['/serial']);
        return false;
      }

    default:
      return true;
  }



};



