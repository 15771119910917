import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AppSettingsService } from './services/app-settings.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: `<div class="app-container" [ngClass]="currentTheme">
              <router-outlet></router-outlet>
            </div>`,
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
    private appSettingsService: AppSettingsService,
    private titleService: Title
  ) {}

  currentTheme!: string;
  translations: any = {};

  ngOnInit(): void {
    this.appSettingsService.setTheme(environment.theme);

    this.currentTheme = this.appSettingsService.getTheme();
    this.translations = this.appSettingsService['translations'];

    this.titleService.setTitle(this.translations.appName);
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
}
